<template>

    <b-modal ref="modal_estatistica"
             modal-class="modal-estatistica"
             v-model="exibe"
             size="xl"
             body-class="app-modal-custom-body"
             header-class="app-modal-custom-header"
             footer-class="app-modal-custom-footer"
             scrollable>

        <div class="container-fluid modal-painel mx-auto h-100">
            <h3 class="modal-titulo mb-3"> Estatísticas </h3>

            <div class="d-flex justify-content-center row my-0">
                <p v-for="dadoAtendimeno in filtradoPorAtendimento" class="text-small" v-if="dadoAtendimeno != ''">{{dadoAtendimeno}}</p>
            </div>

            <div class="d-flex justify-content-center row my-0">
                <p v-for="(dadoTabulacao, i) in filtradoPorTabulacao" class="text-small" v-if="dadoTabulacao != ''">{{i == 0 ? '' : ' /'}} {{dadoTabulacao}}</p>
            </div>

            <div class="row" v-if="exibe">
                <div v-for="(dado,index) in dados" :key="index" class="estilo-panel col-6 mb-3  pb-2">
                    <div class="container ">

                        <div class="row mt-2">
                            <p class="text-center mx-auto font-weight-bold "> {{dado.dados.nome}} </p>
                        </div>
                        <div class="row">
                            <GraficoRosca class="mx-auto" :label="dado.dados.texto" :dados="dado.dados.valor" :responsive="false" />
                        </div>

                    </div>
                </div>
            </div>

        </div>
        <template #modal-footer="{ok}">
            <b-row class="modal-botoes">

                <b-col>
                    <botao-modal-ok type="submit" @click="ok">Ok</botao-modal-ok>
                </b-col>
            </b-row>
        </template>
    </b-modal>

</template>

<script>


    import BotaoModalOk from '../../botoes/BotaoModalOk.vue'
    import GraficoRosca from '../../graficos/GraficoRosca.vue'

    export default {
        name: 'ModalEstatisticasTabulacao',
        components: {
            GraficoRosca,
            BotaoModalOk
        },

        data() {
            return {
                exibe: false,
                dados: null,
            }
        },

        props: {
            filtradoPorAtendimento: Object,
            filtradoPorTabulacao: Array
        },

        computed: {
            getItems() {
                return this.filtradoPor
            }
        },

        methods: {
            show() {
                this.exibe = true
            },
            hide() {
                this.exibe = false

            },
            setDados(data, tabulacoes = null) {
                this.dados = data;

                data.forEach(dado => {
                    let tabulacoesEncontradas = tabulacoes.filter(tabulacao => {
                        return dado.dados.nome == tabulacao.ListaTabulacao.map(value => { return value.Titulo })

                    })

                    tabulacoesEncontradas.reduce((res, obj) => {
                        var indexObj = dado.dados.texto.findIndex(f => { return obj.ListaTabulacao.map(value => { return value.TituloItem }) == f })
                        dado.dados.valor[indexObj] += 1
                    }, [])
                })
            },

        },


    }
</script>
<style>
    /* Sobrepoe o CSS original do modal, fazendo um modal FullScreen */
    .modal-estatistica .modal-dialog {
        max-width: 100%;
        margin: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100vh;
        display: flex;
        position: fixed;
        overflow: auto;
        background-color: transparent;
    }

    .modal-estatistica {
        background-color: transparent;
    }

    .estilo-panel {
        background-color: #f5f5f5;
        box-shadow: 5px 5px 5px #ccc;
    }

    .modal-titulo {
        color: #88A4CA;
        font-weight: 600;
        font-size: 20px;
    }
</style>